import React from "react";
import { Box, Grid, Hidden } from "@material-ui/core";
import { main, Layout } from "helpers/MainLayout";
import MainContainer from "helpers/MainContainer";
import { Body, Caption, Title, Subtitle } from "helpers/text";
import { Section } from "helpers/pages/index/common";
import {
  ScheduleBar,
  ScheduleEvent,
  ScheduleGallery,
} from "helpers/pages/index/schedule";
import TopSection from "helpers/pages/index/TopSection";
import NewsItem from "helpers/pages/index/NewsItem";
import { PCGallery, MobileGallery } from "helpers/pages/index/gallery";
import { makeText } from "helpers/i18n";
import { Sponsors } from "helpers/pages/index/Sponsors";
import Link, { ExternalLink } from "helpers/Link";

// ----------------------------------------------------------------
//     テキスト
// ----------------------------------------------------------------

const useText = makeText({
  ja: {
    topText: "DroidKaigi 2020 開催中止のお知らせ",
    topLink: "https://link.medium.com/69m2oLJAG3",
    about: (
      <React.Fragment>
        DroidKaigiはエンジニアが主役のAndroidカンファレンスです。
        <br />
        Android技術情報の共有とコミュニケーションを目的に、2020年2月20日(木)、21日(金)の2日間開催します。
      </React.Fragment>
    ),
    detail: {
      dateTitle: "日時",
      dateValue: "2020年2月20日 (木)、2月21日(金)",
      placeTitle: "場所",
      placeValue: (
        <React.Fragment>
          五反田TOCビル 13F
          <br />
          〒141-0031 東京都品川区西五反田7-22-17
        </React.Fragment>
      ),
      access: (
        <React.Fragment>
          JR線、都営浅草線「五反田」駅より徒歩8分
          <br />
          東急電鉄目黒線「不動前駅」より徒歩6分
          <br />
          東急電鉄池上線「大崎広小路駅」より徒歩5分
        </React.Fragment>
      ),
      feeTitle: "参加費",
      feeValue: (
        <React.Fragment>
          12月9日(月) 10:00〜 販売開始
          <br />
          ・一般：15000円
          <br />
          ・早期割引：10000円
          <br />
          ・学生割引：4000円
          <br />
          ※全てのチケットにパーティ（1日目）参加権が含まれます。
        </React.Fragment>
      ),
    },
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6486.470629088121!2d139.719063!3d35.621925!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188ae59ebd08f7%3A0xb3823d02815d1d!2z44CSMTQxLTAwMzEg5p2x5Lqs6YO95ZOB5bed5Yy66KW_5LqU5Y-N55Sw77yX5LiB55uu77yS77yS4oiS77yR77yXIFRvY-ODk-ODqw!5e0!3m2!1sja!2sjp!4v1567008652652!5m2!1sja!2sjp",
    news: [
      {
        date: "2020.02.19",
        text: (
          <ExternalLink href="https://medium.com/droidkaigi/droidkaigi-2020%E3%81%AE%E4%B8%AD%E6%AD%A2%E3%81%A82%E6%9C%8820%E6%97%A514-00%E3%81%8B%E3%82%89%E3%81%AE%E5%9F%BA%E8%AA%BF%E8%AC%9B%E6%BC%94%E9%85%8D%E4%BF%A1%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6-e59453445b4b">
            DroidKaigi 2020の中止と2月20日14:00からの基調講演配信について
          </ExternalLink>
        ),
      },
      {
        date: "2020.02.16",
        text: (
          <ExternalLink href="https://link.medium.com/69m2oLJAG3">
            新型コロナウィルスの影響を考慮し、DroidKaigi 2020 は中止となりました
          </ExternalLink>
        ),
      },
      {
        date: "2020.01.31",
        text: (
          <ExternalLink href="https://medium.com/droidkaigi/%E6%96%B0%E5%9E%8B%E3%82%B3%E3%83%AD%E3%83%8A%E3%82%A6%E3%82%A4%E3%83%AB%E3%82%B9%E6%84%9F%E6%9F%93%E7%97%87%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E5%AF%BE%E5%BF%9C%E3%81%AE%E5%91%8A%E7%9F%A5-ebde903fe9b6">
            [更新あり]新型コロナウイルス感染症に関する対応の告知を公開しました
          </ExternalLink>
        ),
      },
      {
        date: "2020.01.21",
        text: <Link to="/timetable">タイムテーブルを公開しました</Link>,
      },
      {
        date: "2019.12.09",
        text: (
          <ExternalLink href="https://droidkaigi.doorkeeper.jp/events/99216">
            チケット販売を開始しました
          </ExternalLink>
        ),
      },
      {
        date: "2019.11.23",
        text: (
          <Link to="/accepted">採択されたセッション一覧を公開しました</Link>
        ),
      },
      {
        date: "2019.10.21",
        text: "セッションの募集を締め切りました",
      },
      {
        date: "2019.09.21",
        text: "セッションの募集を開始しました",
      },
      {
        date: "2019.09.04",
        text: "DroidKaigi 2020 公式サイトオープンしました",
      },
      {
        date: "2019.07.14",
        text: "DroidKaigi 2020 ティザーサイトオープンしました",
      },
    ],
    schedule: {
      proposalStart: { title: "セッション募集開始", date: "2019.09" },
      proposalEnd: { title: "セッション募集締め切り", date: "2019.10" },
      sessions: { title: "セッション採択結果発表", date: "2019.11" },
      ticketSales: { title: "チケット販売開始", date: "2019.12" },
      droidKaigi: { title: "DroidKaigi 開催", date: "2020.02.20" },
    },
  },
  en: {
    topText: "Announcement on the Cancellation of DroidKaigi 2020",
    topLink: "https://link.medium.com/VdPQdfWE43",
    about: (
      <React.Fragment>
        DroidKaigi is a conference tailored for Android developers.
        <br />
        It&apos;s scheduled to take place on the 20 and 21 of February 2020.
      </React.Fragment>
    ),
    detail: {
      dateTitle: "Dates",
      dateValue: "20 - 21 February, 2020",
      placeTitle: "Place",
      placeValue: (
        <React.Fragment>
          Gotanda TOC Buildings 13F
          <br />
          7-22-17 Nishigotanda, Shinagawa City, Tokyo 141-0031
        </React.Fragment>
      ),
      access: (
        <React.Fragment>
          JR lines, Toei Asakusa subway line: 8 minutes walk from Gotanda
          <br />
          Tokyu Meguro line: 6 minutes walk from Fudo-Mae
          <br />
          Tokyu Ikegami line: 5 minutes walk from Osaki-Hirokouji
        </React.Fragment>
      ),
      feeTitle: "Participation Fee",
      feeValue: (
        <React.Fragment>
          9th Dec. 2019, 10:00~ (JST) start date for ticket sales
          <br />
          ・Regular：15000 JPY
          <br />
          ・Early Bird：10000 JPY
          <br />
          ・Student Discount：4000 JPY
          <br />
          This ticket includes a reception, 1-Day Conference
        </React.Fragment>
      ),
    },
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6486.470629088121!2d139.719063!3d35.621925!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188ae59ebd08f7%3A0xb3823d02815d1d!2sToc%20Building%2C%207-ch%C5%8Dme-22-17%20Nishigotanda%2C%20Shinagawa%20City%2C%20T%C5%8Dky%C5%8D-to%20141-0031!5e0!3m2!1sen!2sjp!4v1567008407415!5m2!1sen!2sjp",
    news: [
      {
        date: "2020.02.19",
        text: (
          <ExternalLink href="https://medium.com/droidkaigi/cancellation-of-droidkaigi-2020-and-keynote-live-stream-on-feb-20-14-00-3f4f0a3750a8">
            Cancellation of DroidKaigi 2020 and Keynote Live Stream on Feb 20
            14:00
          </ExternalLink>
        ),
      },
      {
        date: "2020.02.16",
        text: (
          <ExternalLink href="https://link.medium.com/VdPQdfWE43">
            Due to the circumstances surrounding the novel coronaviurs
            infection, Droidkaigi has been declared cancelled.
          </ExternalLink>
        ),
      },
      {
        date: "2020.01.31",
        text: (
          <ExternalLink href="https://medium.com/droidkaigi/announcement-on-the-measures-to-be-taken-for-the-novel-coronavirus-infection-a9e90e4d92b2">
            Updated: Announcement on the Measures to be Taken for the Novel
            Coronavirus Infection has been published
          </ExternalLink>
        ),
      },
      {
        date: "2020.01.21",
        text: <Link to="/timetable">Timetable has been published</Link>,
      },
      {
        date: "2019.12.09",
        text: (
          <ExternalLink href="https://droidkaigi.doorkeeper.jp/events/99216">
            Ticket sales has started
          </ExternalLink>
        ),
      },
      {
        date: "2019.11.23",
        text: <Link to="/accepted">Accepted sessions have been published</Link>,
      },
      {
        date: "2019.10.21",
        text: "Call for Speakers has ended",
      },
      {
        date: "2019.09.21",
        text: "Call for Speakers has opened",
      },
      {
        date: "2019.09.04",
        text: "DroidKaigi 2020 Official Site has been released",
      },
      {
        date: "2019.07.14",
        text: "DroidKaigi 2020 Teaser Site has been released",
      },
    ],
    schedule: {
      proposalStart: {
        title: "Call for Speakers open",
        date: "September 2019",
      },
      proposalEnd: {
        title: "Call for Speakers deadline",
        date: "October 2019",
      },
      sessions: {
        title: "Accepted sessions announcement",
        date: "November 2019",
      },
      ticketSales: { title: "Ticket Sales start", date: "December 2019" },
      droidKaigi: { title: "DroidKaigi", date: "20 - 21 February 2020" },
    },
  },
});

// ----------------------------------------------------------------
//     全体構成
// ----------------------------------------------------------------

const IndexMain: React.FC = () => {
  const text = useText();
  return (
    <Layout>
      <TopSection text={text.topText} to={text.topLink} external />
      <Section>
        <MainContainer>
          <Title>What is DroidKaigi?</Title>
          <Body align="center" xsAlignLeft>
            {text.about}
          </Body>
        </MainContainer>
        <PCGallery mt={5} slidesToShow={5} />
      </Section>
      <MobileGallery />
      <Section>
        <MainContainer>
          {/* グリッドで余計な margin が発生するので marginBottomOffset で調整 */}
          <Title marginBottomOffset={-2}>Overview</Title>
          <Grid container>
            <Grid item sm={5}>
              <Subtitle>{text.detail.dateTitle}</Subtitle>
              <Body>{text.detail.dateValue}</Body>
              <Subtitle>{text.detail.feeTitle}</Subtitle>
              <Body>{text.detail.feeValue}</Body>
            </Grid>
            <Grid item sm={1} />
            <Grid item sm={5}>
              <Subtitle>{text.detail.placeTitle}</Subtitle>
              <Body>{text.detail.placeValue}</Body>
              <Caption color="textSecondary">{text.detail.access}</Caption>
            </Grid>
          </Grid>
        </MainContainer>
      </Section>
      <iframe
        src={text.mapUrl}
        width="100%"
        height="500"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen
      ></iframe>
      <Section>
        <MainContainer>
          <Title>News</Title>
          {text.news.map(({ date, text }) => (
            <NewsItem key={date} date={date} text={text} />
          ))}
        </MainContainer>
      </Section>
      <Section>
        <MainContainer>
          <Title marginBottomOffset={2}>Schedule</Title>
          <Grid container>
            <Grid item sm={6} container>
              <Grid item xs="auto">
                <ScheduleBar />
              </Grid>
              <Grid item xs>
                <Box ml={4}>
                  <ScheduleEvent {...text.schedule.proposalStart} hasBottom />
                  <ScheduleEvent {...text.schedule.proposalEnd} hasBottom />
                  <ScheduleEvent {...text.schedule.sessions} hasBottom />
                  <ScheduleEvent {...text.schedule.ticketSales} hasBottom />
                  <ScheduleEvent {...text.schedule.droidKaigi} />
                </Box>
              </Grid>
            </Grid>
            <Hidden xsDown>
              <Grid sm={6} item>
                <ScheduleGallery />
              </Grid>
            </Hidden>
          </Grid>
        </MainContainer>
      </Section>
      <Sponsors />
    </Layout>
  );
};

export default main(IndexMain);
