import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Box, makeStyles, Paper } from "@material-ui/core";
import { Section } from "./common";
import { Title, H5En } from "helpers/text";
import { useLang } from "helpers/i18n";
import { ExternalLink } from "helpers/Link";
import MainContainer from "helpers/MainContainer";

// ----------------------------------------------------------------
//     各種コンポーネント
// ----------------------------------------------------------------

const useSponsorTitleStyle = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    marginTop: spacing(3.5),
    marginBottom: spacing(1.5),
    [breakpoints.up("sm")]: {
      marginTop: spacing(6),
      marginBottom: spacing(3),
    },
  },
}));

const SponsorTitle: React.FC = ({ children }) => {
  const className = useSponsorTitleStyle();
  return (
    <Box className={className.root} mx={2}>
      <H5En singleLine xsAlignLeft align="center" color="secondary">
        {children}
      </H5En>
    </Box>
  );
};

const useLogoListStyle = makeStyles(({ breakpoints }) => ({
  root: {
    margin: "0 auto",
    [breakpoints.up("xs")]: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
    },
  },
}));

const LogoList: React.FC<{ width: number }> = ({ width, children }) => {
  const classNames = useLogoListStyle();
  return (
    <div className={classNames.root} style={{ maxWidth: width }}>
      {children}
    </div>
  );
};

const useLogoStyle = makeStyles(({ breakpoints }) => ({
  root: {
    margin: 12,
    [breakpoints.down("xs")]: {
      "&.small": {
        margin: 8,
      },
    },
  },
  paper: {
    backgroundSize: "contain",
    backgroundPosition: "center",
    [breakpoints.down("xs")]: {
      margin: "0 auto",
    },
  },
}));

const Logo: React.FC<{
  href: string;
  image: string;
  width: number;
  small?: boolean;
}> = ({ href, image, width, small }) => {
  const classNames = useLogoStyle();
  return (
    <ExternalLink
      className={`${classNames.root} ${small ? "small" : ""}`}
      href={href}
    >
      <Paper
        className={classNames.paper}
        style={{
          width: `${width}px`,
          height: `${width / 2}px`,
          backgroundImage: `url(${image})`,
        }}
      />
    </ExternalLink>
  );
};

// ----------------------------------------------------------------
//     全体構成
// ----------------------------------------------------------------

export const Sponsors: React.FC = () => {
  const allSponsors = useSponsors();
  const sponsors = allSponsors.map((s) => ({
    plan: s.plan,
    key: s.companyName,
    logo: {
      href: s.companyUrl,
      image: s.companyLogoUrl,
    },
  }));

  return (
    <Section>
      <MainContainer>
        <Title>Sponsors</Title>
      </MainContainer>
      <SponsorTitle>PLATINUM SPONSORS</SponsorTitle>
      <LogoList width={(300 + 24) * 2}>
        {sponsors
          .filter((s) => s.plan === "PLATINUM")
          .map((s) => (
            <Logo key={s.key} {...s.logo} width={300} />
          ))}
      </LogoList>
      <SponsorTitle>GOLD SPONSORS</SponsorTitle>
      <LogoList width={(250 + 24) * 3}>
        {sponsors
          .filter((s) => s.plan === "GOLD")
          .map((s) => (
            <Logo key={s.key} {...s.logo} width={250} />
          ))}
      </LogoList>
      <SponsorTitle>SUPPORTER</SponsorTitle>
      <LogoList width={(160 + 24) * 4}>
        {sponsors
          .filter((s) => s.plan === "SUPPORTER")
          .map((s) => (
            <Logo key={s.key} {...s.logo} small width={160} />
          ))}
      </LogoList>
      <SponsorTitle>TECHNICAL SUPPORT FOR NETWORK</SponsorTitle>
      <LogoList width={(160 + 24) * 4}>
        {sponsors
          .filter((s) => s.plan === "COMMITTEE_SUPPORT")
          .map((s) => (
            <Logo key={s.key} {...s.logo} width={160} />
          ))}
      </LogoList>
    </Section>
  );
};

// ----------------------------------------------------------------
//     データ取得
// ----------------------------------------------------------------

type Plans = "PLATINUM" | "GOLD" | "SUPPORTER" | "COMMITTEE_SUPPORT";

interface Info {
  id: string;
  plan: Plans;
  companyUrl: string;
  companyName: string;
  companyLogoUrl: string;
  sort: number;
}

const useSponsors = (): Info[] => {
  const lang = useLang();
  const {
    allSponsors: { data },
  } = useStaticQuery(graphql`
    {
      allSponsors {
        data {
          companyLogoUrl
          companyName {
            en
            ja
          }
          companyUrl
          id
          sort
          plan
        }
      }
    }
  `);

  /* eslint-disable @typescript-eslint/no-explicit-any */
  return data.map((s: any) => ({
    id: s.id,
    plan: s.plan,
    companyUrl: s.companyUrl,
    companyName: s.companyName[lang],
    companyLogoUrl: s.companyLogoUrl,
    sort: s.sort,
  }));
  /* eslint-enable */
};
