import React, { useState, useCallback } from "react";
import { GlobalThemeProvider } from "helpers/theme";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import MenuIcon from "@material-ui/icons/Menu";
import {
  Divider,
  ListSubheader,
  SvgIcon,
  Switch,
  Box,
  Typography,
} from "@material-ui/core";
import { useDarkMode, ModeProvider } from "./mode";
import Footer from "./MainLayout/Footer";
import { I18nContextProvider, makeText, useLang } from "./i18n";
import Link from "./Link";
import LangMenu from "./MainLayout/LangMenu";
import { H3En } from "./text";
import Metadata from "./MainLayout/Metadata";

// ----------------------------------------------------------------
//     テキスト
// ----------------------------------------------------------------

const useText = makeText({
  ja: {
    homeLink: "ホーム",
    timetableLink: "タイムテーブル",
    staffsLink: "スタッフ",
    darkModeTitle: "ダークテーマ",
  },
  en: {
    homeLink: "Home",
    timetableLink: "Timetable",
    staffsLink: "Committee Members",
    darkModeTitle: "Dark Theme",
  },
});

// ----------------------------------------------------------------
//     コンポーネント
// ----------------------------------------------------------------

export const main = <P extends Record<string, unknown>>(
  Component: React.FC<P>
): React.FC<P> => (props) => (
  <ModeProvider>
    <I18nContextProvider>
      <Component {...props} />
    </I18nContextProvider>
  </ModeProvider>
);

export const Layout: React.FC<{ title?: string; description?: string }> = ({
  title,
  description,
  children,
}) => (
  <React.Fragment>
    <Metadata title={title} description={description} />
    <MainLayoutMain title={title}>{children}</MainLayoutMain>
  </React.Fragment>
);

/**
 * App Bar やドロワーを含んだ共通レイアウトです。
 */
const MainLayoutMain: React.FC<{ title?: string }> = ({ title, children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const openDrawer = useCallback(() => setDrawerOpen(true), []);
  const closeDrawer = useCallback(() => setDrawerOpen(false), []);
  const [dark, setDark] = useDarkMode();
  const lang = useLang();

  return (
    <GlobalThemeProvider dark={dark} setDark={setDark}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Menu"
            onClick={openDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Box ml={2}>
            <Typography variant="h6">{title}</Typography>
          </Box>
          <div style={{ flexGrow: 1 }} />
          <LangMenu />
          <Box width={32} textAlign="right">
            <H3En variant="button">{lang}</H3En>
          </Box>
        </Toolbar>
        <SwipeableDrawer
          open={drawerOpen}
          onOpen={openDrawer}
          onClose={closeDrawer}
        >
          <MainDrawer />
        </SwipeableDrawer>
      </AppBar>
      <Toolbar />
      {children}
      <Footer />
    </GlobalThemeProvider>
  );
};

/**
 * ドロワーの中身です。
 */
const MainDrawer: React.FC = () => {
  const [dark, setDark] = useDarkMode();
  const { homeLink, timetableLink, staffsLink, darkModeTitle } = useText();
  const toggleDark = useCallback(() => setDark(!dark), [dark, setDark]);

  return (
    <div style={{ width: "250px" }}>
      <List subheader={<ListSubheader>Menu</ListSubheader>}>
        <ListItem button key="Home" component={Link} to="/">
          <ListItemIcon>
            <SvgIcon>
              <path d="M8 17V11H12V17H17V9H20L10 0L0 9H3V17H8Z" />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary={homeLink} />
        </ListItem>
        <ListItem button key="Timetable" component={Link} to="/timetable">
          <ListItemIcon>
            <SvgIcon>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 2H16C17.1 2 18 2.9 18 4V18C18 19.1 17.1 20 16 20H2C0.89 20 0 19.1 0 18L0.01 4C0.01 2.9 0.89 2 2 2H3V0H5V2H13V0H15V2ZM2 8V18H16V8H2ZM16 6H2V4H16V6ZM14 11H9V16H14V11Z"
              />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary={timetableLink} />
        </ListItem>
        <ListItem button key="Staff" component={Link} to="/staffs">
          <ListItemIcon>
            <SvgIcon>
              <path d="M16 11C17.66 11 18.99 9.66 18.99 8C18.99 6.34 17.66 5 16 5C14.34 5 13 6.34 13 8C13 9.66 14.34 11 16 11ZM8 11C9.66 11 10.99 9.66 10.99 8C10.99 6.34 9.66 5 8 5C6.34 5 5 6.34 5 8C5 9.66 6.34 11 8 11ZM8 13C5.67 13 1 14.17 1 16.5V19H15V16.5C15 14.17 10.33 13 8 13ZM16 13C15.71 13 15.38 13.02 15.03 13.05C16.19 13.89 17 15.02 17 16.5V19H23V16.5C23 14.17 18.33 13 16 13Z" />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary={staffsLink} />
        </ListItem>

        <Divider />
        <ListItem key="DarkTheme">
          <ListItemIcon>
            <SvgIcon>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.9999 4V8.69L23.3099 12L19.9999 15.31V20H15.3099L11.9999 23.31L8.68994 20H3.99994V15.31L0.689941 12L3.99994 8.69V4H8.68994L11.9999 0.69L15.3099 4H19.9999ZM10.2099 7.46C10.8399 7.17 11.5499 7 12.2899 7C15.0499 7 17.2899 9.24 17.2899 12C17.2899 14.76 15.0499 17 12.2899 17C11.5499 17 10.8399 16.83 10.2099 16.54C11.9299 15.75 13.1299 14.01 13.1299 12C13.1299 9.99 11.9299 8.25 10.2099 7.46Z"
              />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary={darkModeTitle} />
          <Switch
            edge="end"
            onChange={toggleDark}
            checked={dark}
            color="primary"
          />
        </ListItem>
      </List>
    </div>
  );
};
